import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import "./listacliente.css";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { AuthContext } from "../Acesso/Context/auth";

function ListaCliente({ arrayClientes, clickDelete }) {
  const [filtroDataVenda01, setFiltroDataVenda01] = useState(
    localStorage.getItem("filtroDataVenda01") || ""
  );
  const [filtroDataVenda02, setFiltroDataVenda02] = useState(
    localStorage.getItem("filtroDataVenda02") || ""
  );
  const [quantidadeClientes, setQuantidadeClientes] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [exclusiveUser, setExclusiveUser] = useState(false);
  const [isAdmUser, setIsAdmUser] = useState(false);
  const { setLogado } = useContext(AuthContext);
  const auth = getAuth();

  useEffect(() => {
    localStorage.setItem("filtroDataVenda01", filtroDataVenda01);
    localStorage.setItem("filtroDataVenda02", filtroDataVenda02);
  }, [filtroDataVenda01, filtroDataVenda02]);

  const sortedClientes = React.useMemo(() => {
    return arrayClientes
      .slice()
      .sort((a, b) => new Date(b.data) - new Date(a.data));
  }, [arrayClientes]);

  const formattedClientes = React.useMemo(() => {
    return sortedClientes.map((cliente) => ({
      ...cliente,
      formattedData01: formatarData(cliente.data),
      formattedData02: formatarData(cliente.venc2),
    }));
  }, [sortedClientes]);

  function formatarData(data) {
    if (typeof data === "string" && data.includes("-")) {
      const partes = data.split("-");
      return `${partes[2]}-${partes[1]}-${partes[0]}`;
    } else {
      return "N/A";
    }
  }

  const handleVerificarPagos = useCallback(async () => {
    try {
      const db = getFirestore();
      const userId = auth.currentUser?.uid;
      const adminUsers = [
        "yezea9eucLS9O1Pyl1LDzGXNTkE2",
        "o0MWkxE9M1fXOFbyuFzo96NG3rv2",
        "azI8ZQyYzoTZIknOuQHOPvQiVg12",
      ];
      const exclusiveUsers = [
        "yezea9eucLS9O1Pyl1LDzGXNTkE2",
        "o0MWkxE9M1fXOFbyuFzo96NG3rv2",
        "azI8ZQyYzoTZIknOuQHOPvQiVg12",
      ];

      if (adminUsers.includes(userId)) {
        setIsAdmUser(true);
      } else if (exclusiveUsers.includes(userId)) {
        setExclusiveUser(true);
      }

      const userAllViwer = [
        "o0MWkxE9M1fXOFbyuFzo96NG3rv2",
        "azI8ZQyYzoTZIknOuQHOPvQiVg12",
        "JErLzWpMaDhnK7FQCNyWxovFGF92",
        "Hk5ute6UesQM6R438MyBu6Cc9TF2",
        "W4OmQKw6gWTnWioUENmEpPjwb4m1",
        "JiQlIYtqE6X4cuhAefF655384L42",
        "yezea9eucLS9O1Pyl1LDzGXNTkE2",
        "aWFWUvSEOxYmBBsJiTZR7KLD2X23",
        "3RmT5lBN8bhHt6pdHyOq9oBW6yD3",
        "fzPJ8yp4OJPAvGcBXP0aVD0TYe62",
      ];

      let q;
      if (userAllViwer.includes(userId)) {
        q = query(collection(db, "clientes"));
      } else {
        q = query(collection(db, "clientes"), where("userId", "==", userId));
      }

      const querySnapshot = await getDocs(q);
      const listaCli = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        fantasia: doc.data().fantasia,
        cpf: doc.data().cpf,
        nome: doc.data().nome,
        email: doc.data().email,
        fone: doc.data().fone,
        data: doc.data().data,
        razao: doc.data().razao,
        venc: doc.data().venc,
        whatsapp: doc.data().whatsapp,
      }));

      setQuantidadeClientes(listaCli.length);
      setTotalPages(Math.ceil(listaCli.length / 10));
    } catch (error) {
      console.error("Erro ao obter dados:", error);
    }
  }, [auth]);

  const Logout = () => {
    setLogado(false);
    localStorage.removeItem("logado");
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLogado(true);
        handleVerificarPagos();
      } else {
        setLogado(false);
      }
    });
    return () => unsubscribe();
  }, [auth, setLogado, handleVerificarPagos]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
    window.scrollTo(0, 0);
  };

  const clientesPerPage = 10;
  const filteredClientes = formattedClientes.filter(
    (cliente) =>
      (!filtroDataVenda01 || cliente.data === filtroDataVenda01) &&
      (!filtroDataVenda02 || cliente.venc2 === filtroDataVenda02)
  );

  const indexOfLastCliente = currentPage * clientesPerPage;
  const indexOfFirstCliente = indexOfLastCliente - clientesPerPage;
  const currentClientes = filteredClientes.slice(
    indexOfFirstCliente,
    indexOfLastCliente
  );

  useEffect(() => {
    setTotalPages(Math.ceil(filteredClientes.length / clientesPerPage));
  }, [filteredClientes]);

  return (
    <div>
      <div className="divAss">
        <div className="divDate">
          <p className="text-center">DATA DE VENCIMENTO</p>
          <input
            type="date"
            value={filtroDataVenda01}
            onChange={(e) => setFiltroDataVenda01(e.target.value)}
            className="form-control date date-config"
          />
        </div>
      </div>
      <table className="table table-hover table-bordered">
        <thead>
          <tr className="table-primari">
            <th scope="col" className="col-acao text-center">
              CNPJ/CPF
            </th>
            <th scope="col" className="col-acao text-center">
              NOME
            </th>
            <th scope="col" className="col-acao text-center">
              E-MAIL
            </th>
            <th scope="col" className="col-acao text-center">
              OPERADOR
            </th>
            <th scope="col" className="col-acao text-center">
              WHATSAPP
            </th>
            <th scope="col" className="col-acao text-center">
              VENCIMENTO
            </th>
            <th scope="col" className="col-acao text-center">
              <i className="fa-solid fa-clipboard icon-u"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentClientes.map((cliente) => (
            <tr key={cliente.id} className="table-light">
              <th scope="row" className="align-middle">
                <Link
                  to={`/app/home/fichacliente/${cliente.id}`}
                  className="fa-solid fa-list icone-acao1 align-middle"
                ></Link>
                {cliente.cpf}
              </th>
              <td className="align-middle text-center ">
                {cliente.nome || "N/A"}
              </td>
              <td className="align-middle text-center ">
                {cliente.email || "N/A"}
              </td>
              <td className="align-middle text-center ">
                {cliente.operador || "N/A"}
              </td>
              <td className="align-middle text-center ">
                {cliente.whatsapp || "N/A"}
              </td>
              <td className="align-middle text-center ">
                {cliente.formattedData01}
              </td>
              <td className="align-middle text-center ">
                <div className="d-flex gap-2">
                  <Link to={`/app/home/editarcliente/${cliente.id}`}>
                    <i className="fa-solid fa-pen-to-square icone-acao"></i>
                  </Link>

                  <Link to={`/app/home/qrcode/${cliente.id}`}>
                    <i className="fa-solid fa-qrcode icone-acao"></i>
                  </Link>

                  <Link to="#" onClick={() => clickDelete(cliente.id)}>
                    <i className="fa-solid fa-trash icone-acao red"></i>
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </div>
  );
}
const Pagination = ({ currentPage, totalPages, handlePageChange }) => (
  <div className="row">
    <div className="col-12 text-center">
      <div className="pagination">
        <button
          className="btn btn-outline-secondary"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <div className="pagination text-light">
          <p>{currentPage}</p>
          <p>-</p>
          <p>{totalPages}</p>
        </div>
        <button
          className="btn btn-outline-secondary"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Próximo
        </button>
      </div>
    </div>
  </div>
);

export default ListaCliente;
